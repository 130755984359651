import "./plugins/registerServiceWorker";
import "./styles/app.scss";
// import "./plugins/bugsnag";
import "./plugins/axios";
import "./plugins/filters";
import Vue from "vue";
import App from "./App";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import router from "./router";
import Auth from "./services/Auth";
import store from "./store";

Vue.config.productionTip = process.env.NODE_ENV !== "production";

Vue.prototype.$product = process.env.VUE_APP_PRODUCT;

// Geofencing radius (in meters)
Vue.prototype.$geofencingRadius = 500;

const auth = new Auth();
auth.init().then(() => {
  new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
